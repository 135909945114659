import Banner from "../components/Banner"
import CountDown from "../components/CountDown"
import NameInformation from "../components/NameInformation"
import DateInformation from "../components/DateInformation"
import RSVP from "../components/RSVP"
import Thanks from "../components/Thanks"

const MainPage = () => {
    return (
        <div>
            <div>
                <Banner />
            </div>
            <div>
                <CountDown />
            </div>
            <div>
                <NameInformation />
            </div>
            <div>
                <DateInformation />
            </div>
            <div>
                <RSVP />
            </div>
            <div>
                <Thanks />
            </div>
        </div>
    )
}

export default MainPage