import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import FirstPage from './pages/first';
import MainPage from './pages/MainPage';
import AttendanceList from './pages/AttendanceList';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<FirstPage />} />
        <Route path='/lutfi-layla/:to' element={<MainPage />} />
        <Route path='/lutfi-layla/attendance' element={<AttendanceList />} />
      </Routes>
    </Router>
  );
}

export default App;
