import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Form, Row } from "react-bootstrap"

const RSVP = () => {

    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api-undangan.kuninglangsat.my.id/comment')
                setData(response.data)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchData()
    }, [])

    const [nama, setNama] = useState('')
    const [ucapan, setUcapan] = useState('')

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            const response = await axios.post('https://api-undangan.kuninglangsat.my.id/comment', { nama, ucapan })
            console.log(response.data)
            setNama('')
            setUcapan('')
            window.location.reload()
        } catch (error) {
            console.error('Error submitting data:', error)
        }
    }

    // Kirim Ke Database Kolom Attendance
    const [name, setName] = useState('')
    const [attendance, setAttendance] = useState('')
    const [count, setCount] = useState(1)

    const handleSubmitAttend = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post('https://api-undangan.kuninglangsat.my.id/attendance', { name, attendance, count })
            console.log(response.data)

            setName('')
            setAttendance('')
            setCount(1)
        } catch (error) {
            console.error('Gagal mengirim data:', error)
        }
    }

    return (
        <div className='RSVP'>
            <div className='RSVP-head'>
                <h1>RSVP</h1>
                <p>Kehadiran Bapak/ Ibu/ Saudara/ i merupakan suatu kebahagiaan bagi kami</p>
            </div>
            <div className='RSVP-rsvp-box'>
                <Form onSubmit={handleSubmitAttend}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className="mb-0">Nama</Form.Label>
                                <Form.Control type="text"
                                    placeholder="Nama"
                                    className="RSVP-rsvp-box-form"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row xs={1} sm={1} md={1} lg={2} xl={2} xxl={2}>
                        <Col>
                            <Form.Group>
                                <Form.Label className="mb-0">Konfirmasi Kehadiran</Form.Label>
                                <Form.Select as='select'
                                    value={attendance}
                                    onChange={(e) => setAttendance(e.target.value)}
                                    className="RSVP-rsvp-box-form"
                                >
                                    <option hidden>Konfirmasi Kehadiran</option>
                                    <option value="Hadir">Hadir</option>
                                    <option value="Tidak Hadir">Tidak Hadir</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className="mb-0">Jumlah Tamu</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Jumlah Tamu"
                                    className="RSVP-rsvp-box-form"
                                    min={0}
                                    value={count}
                                    onChange={(e) => setCount(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="RSVP-rsvp-box-button-parent">
                            <Button type='submit' className='RSVP-rsvp-box-button'>Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className='RSVP-head'>
                <h1>Make A Wish</h1>
                <p>Doa dari Bapak/ Ibu/ Saudara/ i selalu kami harapkan</p>
            </div>
            <div className='RSVP-rsvp-box'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className="mb-0">Nama</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nama"
                                    className="RSVP-rsvp-box-form"
                                    value={nama}
                                    onChange={(e) => setNama(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className="mb-0">Ucapan</Form.Label>
                                <Form.Control as='textarea'
                                    placeholder="Ketik Disini"
                                    rows={4}
                                    className="RSVP-wish-textarea"
                                    value={ucapan}
                                    onChange={(e) => setUcapan(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="RSVP-rsvp-box-button-parent">
                            <Button type='submit' className='RSVP-rsvp-box-button'>Submit</Button>
                        </Col>
                    </Row>
                </Form>
                <div className="RSVP-wish-box-ucapan">
                    <div className="RSVP-wish-box-ucapan-count">
                        <p className="mb-0">Wishes</p>
                    </div>
                    {data.map(item => (
                        <div className="RSVP-wish-box-ucapan-content">
                            {/* calon Map */}
                            <p className="mb-0 RSVP-wish-box-ucapan-content-name" key={item.id}>{item.nama}</p>
                            <p className="RSVP-wish-box-ucapan-content-ucapan" key={item.id}>{item.ucapan}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RSVP