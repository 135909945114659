import Top from '../assets/images/top.png'
import Bottom from '../assets/images/bottom.png'

const NameInformation = () => {
    return (
        <div className='name-information'>
            <div className='name-information-image-top'>
                <img className='name-information-image-sticky-top' src={Top} alt="" />
            </div>
            <div className='name-information-content'>
                <p className='greeting'>
                    Bismillahirrahmanirrahim, <br />
                    Assalamualaikum Warahmatullahi Wabarakatuh
                </p>
                <p className='intro'>
                    Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta’ala, In Syaa Allah kami akan
                    menyelenggarakan acara pernikahan :
                </p>
                <h1 className='man mb-0'>
                    Muhammad Lutfi
                </h1>
                <p className='man-parent'>Putra dari Bpk. Fitriansyah dan Ibu Nikmatul Khalik </p>
                <h1>&</h1>
                <h1 className='woman mb-0'>Layla Afifa</h1>
                <p className='woman-parent'>Putri dari Bpk. Bambang Syaifuddin dan Ibu Sahmun Hayati</p>
            </div>
            <div className='name-information-image-bottom'>
                <img className='name-information-image-sticky-bottom' src={Bottom} alt="" />
            </div>
        </div>
    )
}

export default NameInformation