import '../index.css'
import Music from '../assets/sound/music.mp3'
import { Link } from 'react-scroll'
import { useParams } from 'react-router-dom'

const Banner = () => {
    const { to } = useParams()

    function play() {
        new Audio(Music).play()
    }

    return (
            <div className='banner'>
                <div className='banner-content'>
                    <h3 className='mb-0'>The Wedding Of</h3>
                    <h1>Lutfi & Layla</h1>
                    <p>Kepada Yth. Bapak/ Ibu/ Saudara/ i</p>
                    <p className='mb-0'>{to}</p>
                    <button className='banner-button'>
                        <Link
                            onClick={play}
                            activeClass="active"
                            to="countDown"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                        > Buka Undangan</Link>
                    </button>
                </div>
            </div>
    )
}

export default Banner