import Logo from '../assets/images/logo-with-text.png'

const Thanks = () => {
    return(
        <div className="thanks">
            <div className="thanks-content">
                <p className="mb-0">Atas kehadiran dan do’a restu dari Bapak/Ibu/Saudara/i sekalian, kami mengucapkan Terima Kasih</p>
                <h1>Lutfi & Layla</h1>
            </div>
            <div className="watermark">
                <p className="mb-0">Made By:</p>
                <img src={Logo} alt=""/>
                <p>kuninglangsat.my.id</p>
            </div>
        </div>
    )
}

export default Thanks