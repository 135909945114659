import Flower from '../assets/images/flower1.png'
import Line13 from '../assets/images/line13.png'

const DateInformation = () => {
    return (
        <div className='date-information'>
            <img className='date-information-flower' src={Flower} alt="" />
            <div className='date-information-content'>
                <h1>Akad Nikah</h1>
                <img className='date-information-content-line' src={Line13} alt='' />
                <p className='mb-0'>Ahad</p>
                <div className='date-information-content-date'>
                    <div className='date-information-content-date-spec'>May</div>
                    <div className='date-information-content-date-spec center-spec'>26</div>
                    <div className='date-information-content-date-spec'>2024</div>
                </div>
                <p className='mb-3'>
                    Pukul<br />
                    09:00 - Selesai
                </p>
                <img className='date-information-content-line' src={Line13} alt='' />
                <p className='date-information-adress'>
                    Tempat Acara: <br />
                    Jl. Kenari RT/RW. 07/02, Desa Karang Bunga Kec. Mandastana Kab. Barito Kuala
                </p>

                <a rel='noreferrer' target='_blank' href='https://maps.app.goo.gl/KCJErhCK4zWVtdBk9'>
                    <button className='date-information-content-button'>
                        Lihat Lokasi
                    </button>
                </a>

            </div>
        </div>
    )
}

export default DateInformation