import React, { useEffect, useRef, useState } from 'react'
import { Image } from 'react-bootstrap'
import Ornament1 from '../assets/images/img1.png'

const CountDown = () => {
    const [timerDays, setTimeDays] = useState('00')
    const [timerHours, setTimeHours] = useState('00')
    const [timerMinutes, setTimeMinutes] = useState('00')
    const [timerSeconds, setTimeSeconds] = useState('00')

    let interval = useRef()

    const startTimer = () => {
        const countdownDate = new Date('May 26, 2024 09:00:00').getTime()

        interval = setInterval(() => {
            const now = new Date().getTime()
            const distance = countdownDate - now

            const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)

            if (distance < 0) {
                clearInterval(interval.current)
            } else {
                setTimeDays(days)
                setTimeHours(hours)
                setTimeMinutes(minutes)
                setTimeSeconds(seconds)
            }

        }, 1000)
    }

    useEffect(() => {
        startTimer()
        return () => {
        }
        clearInterval(interval.current)
    })

    return (
        <div className='countdown' id='countDown'>
            <div className='countdown-wrapper'>
                <div className='countdown-wrapper-content'>
                    <h1>{timerDays}</h1>
                    <p>Days</p>
                    <Image src={Ornament1} fluid/>
                </div>
                <div className='countdown-wrapper-content'>
                    <h1>{timerHours}</h1>
                    <p>Hours</p>
                    <Image src={Ornament1} fluid/>
                </div>
                <div className='countdown-wrapper-content'>
                    <h1>{timerMinutes}</h1>
                    <p>Minutes</p>
                    <Image src={Ornament1} fluid/>
                </div>
                <div className='countdown-wrapper-content'>
                    <h1>{timerSeconds}</h1>
                    <p>Seconds</p>
                    <Image src={Ornament1} fluid/>
                </div>
            </div>
        </div>
    )
}

export default CountDown