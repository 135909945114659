import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';

const AttendanceList = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api-undangan.kuninglangsat.my.id/attendance');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div>
            <h1>Data Konfirmasi Kehadiran:</h1>

            <Table responsive="sm">
                <thead>
                    <tr>
                        <th>Nama</th>
                        <th>Status</th>
                        <th>Jumlah</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr>
                            <td>{item.name}</td>
                            <td>{item.attendance}</td>
                            <td>{item.count}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default AttendanceList